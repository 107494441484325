import {attachBLDescription}  from './blDescriptions.js';

// Callback to dynamically display selector information for
// Phase BL
export function phase_bl_selector_change(){
    attachBLDescription(
        $("#bl_selector").val(),
        $("#blDescriptionText"),
        window.blDescriptions,
        "Phase BL Descriptions");
}

// Callback to dynamically display selector information for
// Phase Submission BL
export function phase_submission_bl_selector_change() {
    attachBLDescription(
        $("#submission_bl_selector").val(),
        $("#submissionBlDescriptionText"),
        window.sblDescriptions,
        "Submission BL Descriptions");
}

// Dynamically display selector information for
// Phase Report BL
export function phase_report_bl_selector_change(){
    attachBLDescription(
        $("#report_bl_selector").val(),
        $("#reportBlDescriptionText"),
        window.rblDescriptions,
        "Report BL Descriptions");
}

// Callback to dynamically display selector information for
// SR status change BL
export function scoring_run_change_bl_selector_change() {
  attachBLDescription(
    $("#sr_change_bl_selector").val(),
    $("#scoringRunBlDescriptionText"),
    window.srblDescriptions,
    "Scoring Run Status Change BL Descriptions");
}

// Refresh display once at load time
window.addEventListener("load", (event) => {
//$('document').ready(function() {
    // Enable popovers
    $('[data-toggle="popover"]').popover();
    // Enable Automatic BL Descriptions (Help)
    phase_bl_selector_change();
    phase_submission_bl_selector_change();
    phase_report_bl_selector_change();

    // Refresh Timeline Chart from data
    scoring_run_change_bl_selector_change();
    'use strict';
    if (typeof (phasedata) != 'undefined') {
        // Enable Phase Charting
        const element = document.getElementById('chart');
        const timeline = new TimelineChart(element, phasedata, {
            enableLiveTimer: true,
            tip: function(d) {
                return d.at ||
                    `<b>${d.label}</b><br><small>${d.from}<br>${d.to}</small>`;
            }
        }); //.onVizChange(e => console.log(e));
    }
})
